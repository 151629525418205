import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { SampleWorkflow } from 'state-domains/domain/subscription';

import { buildEditSampleWorkflowsUrl } from '../../../network/urls';
import { sendDeleteRequestWithXSRFToken, convertToCamel } from '../../../utils';

export const deleteSampleWorkflow = (id: string): Observable<SampleWorkflow> =>
    sendDeleteRequestWithXSRFToken(buildEditSampleWorkflowsUrl(id)).pipe(
        switchMap(({ response }: AjaxResponse<SampleWorkflow>) => {
            const convertedWorkflow = convertToCamel<SampleWorkflow>(response);
            return observableOf(convertedWorkflow);
        }),
    );
